<template>
  <div class="section">
    <div class="container">
      <div id="first-screen">
        <div class="row">
          <div class="col-lg-4">
            <div class="violet-bg d-flex align-items-center">
              <img src="../../assets/icons/Tes-pencil.svg" alt="" class="mx-auto">
            </div>
            <h5>Kerjakan tes ini secara sungguh-sungguh</h5>
          </div>
          <div class="col-lg-4">
            <div class="violet-bg d-flex align-items-center">
              <img src="../../assets/icons/Stopwatch.svg" alt="" class="mx-auto">
            </div>
            <h5>Tes ini membutuhkan waktu 7-10 menit</h5>
          </div>
          <div class="col-lg-4">
            <div class="violet-bg d-flex align-items-center">
              <img src="../../assets/icons/Instruction.svg" alt="" class="mx-auto">
            </div>
            <h5>Baca dan pahami instruksi baik-baik</h5>
          </div>
          <div class="col-lg-12 text-center">
            <router-link class="btn btn-outline-violet" :to="{name: 'instruction'}">
              Selanjutnya
              <fa-icon icon="arrow-right" class="ml-2"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeTest"
};
</script>

<style scoped>

</style>
